@function get-vw($target)
{
  $vw-context: (1600 * 0.01);
  @return ($target / $vw-context) * 1vw;
}
html,body {
  overflow-x: hidden;
}
body{
  //padding-top: 50px;
  font-family: 'Montserrat', 'Open Sans';
}
#topHeader{
  background-color: #fff;
  padding-top: 0;
}
#statusMessage{
  position: fixed;
  bottom: 0px;
  width: 100%;
}
.navbar {
  margin-top:16px;
  margin-bottom:0;
  .logo {
    text-align:right;
    img {
      max-width:164px;
    }
  }
}
.toploginbar {
  margin-top:11px;
  p {
    position:relative;
      input[type="text"], input[type="password"] {
      width:100%;
      height: 31px;
      object-fit: contain;
      border-radius: 3px;
      background-color: #ffffff;
      border: solid 1px #dadcde;
      outline:none;
      box-shadow:none;
      padding:0 20px 0 16px;
      font-family: 'Lato';
      font-size: 10.5px;
      color: #dadcde;
    }
    i.fa {
        position:absolute;
        right:8px;
        top:50%;
        transform:translate(0, -50%);
        color: #dadcde;
        font-size:10.5px;
    }
    input[type="submit"] {
      width:100%;
      background-color: #b03427;
      border:none;
      outline:none;
      max-width: 58px;
      line-height: 31px;
      font-family: 'Lato';
      font-size: 10.5px;
      font-weight: 900;
      color: #ffffff;
      text-align:center;
      text-transform:uppercase;
      border-radius:3px;
    }
    a.forgotpasslink {
      font-family: 'Lato';
      font-size: 10.5px;
      line-height: 1.57;
      text-align: left;
      color: #a1b1bc;
      margin-left:15px;
      text-decoration:none;
      span {
        font-weight: bold;
        color: #b03427;
      }
    }
  }
}
.headernav {
  width: calc(100% + 62px);
  margin-left: -31px;
  margin-top:19px;
  background-color: #b03427;
  ul.nav {
    float: none;
    margin: 0 auto;
    text-align: center;
    li {
      display:inline-block;
      float:none; 
      a {
        font-family: 'Montserrat';
        font-size: 13.1px;
        font-weight: bold;
        line-height: 32px;
        letter-spacing: 0.3px;
        text-align: left;
        color: #fff;
        padding-top:0;
        padding-bottom:0;
        text-transform:uppercase;
        position:relative;
        background:transparent !important;
        padding-left:16px;
        padding-right:16px;
        &:hover {
          &:after {
            content:'';
            position:absolute;
            left:16px;
            right:16px;
            bottom:4px;
            background:#fff;
            height:2px;            
          }
        }
        &.active:after {
            content:'';
            position:absolute;
            left:15px;
            right:15px;
            bottom:4px;
            background:#fff;
            height:2px;            
         }
      }
    }
  }
}
.main-content{
  .header-content{
    background: url(../images/homebg.png) no-repeat center;
    background-size: cover;
    height: calc(100vh - 122px);
    position:relative;
    &.productbg {
      background: url(../images/productheaderbg.png) no-repeat center;
      max-height: 254px;
      p.hometitle {
            max-width: 1138px;
            left: 0;
            right: 0;
            font-size: 65px;
            margin: 0 auto;
            padding-bottom: 70px;
      }
      .cardbg {
        background: url(../images/headercard.png);
        width: 45px;
        height: 40px;
        position: absolute;
        display: block;
        pointer-events: none;
        right: 40px;
      }
    }
    p.hometitle {
      position:absolute;
      font-family: 'Montserrat';
      font-size: 84.6px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 2.1px;
      text-align: left;
      color: #ffffff;
      max-width:481px;
      left: 10%;
      border: 3px solid #fff;
      padding: 49px 30px 56px 31px;
      top: 50%;
      transform: translate(0, -50%);
      a.explore {
        width:171px;
        height:43px;
        display:block;
        position:absolute;
        bottom:-23px;
        background:url(../images/explorebutton.png);
        right:35px;
      }
    }
  }
}
.maincont {
  margin-top:43px;
  margin-bottom:52px;
}
.contheading {
  text-align:right;
  font-family: 'Montserrat';
  font-size: 30px;
  font-weight: bold;
  padding-right:21px;
  color: #393b44;
  border-right:2px solid #b03427;
}
.tabs-menu {
  li {
    display:inline-block;
    padding-left:16px;
    padding-right:16px;
    line-height:30px;
    position:relative;
    a {
      font-family: 'Montserrat';
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.3px;
      color: #393b44;
      text-transform:uppercase;
      text-decoration:none;
    }
    &.current  {
      a {
        color:#b03427;
      }
      &:after {
        content:'';
        position:absolute;
        bottom:0;
        left:15px;
        right:15px;
        height:2px;
        background:#b03427;
      }
    }
  }
}
.tab-content {
    display: none;
    overflow: hidden;
    .contentright {
      text-align:center;
      h2 {
        font-family: Montserrat;
        font-size: 21.4px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.5px;
        text-align: center;
        color: #393b44;
        text-transform:uppercase;
        margin-bottom:28px;
      }
      p {
        font-family: Open Sans;
        font-size: 11.3px;
        line-height: 1.42;
        text-align: center;
        color: #393b44;
        position:relative;
        padding-bottom:22px;
        &:after {
          content:'';
          position:absolute;
          bottom:0;
          width:36px;
          height:2px;
          background-color: #393b44;
          left:0;
          right:0;
          margin:0 auto;
        }
      }
    }
  .missionvisionarea {
    padding-top:64px;
    padding-bottom:51px;
    background:#393b44;
    margin-left:15px;
    margin-top:24px;
    margin-bottom:43px;
    text-align:center;
    h2 {
      font-family: 'Montserrat';
      font-size: 21.4px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.5px;
      text-align: center;
      color: #ffffff;
      margin-bottom:30px;
    }
    p {
      font-family: Open Sans;
      font-size: 11.3px;
      line-height: 1.42;
      text-align: center;
      color: #ffffff;
      padding-bottom:20px;
      position:relative;
      &:after {
        content:'';
        position:absolute;
        width:35px;
        height:2px;
        background:#fff;
        bottom:0;
        left:0;
        right:0;
        margin:0 auto;
      }
    }
  }
}

#minitfs {
 display: block;  
 img {
  width:100%;
 } 
}
#Innovate {
  img {
    width:100%;
  }
  p.innovatep {
    &:after {
      display:none;
    }
  }
  h3 {
    font-family: Open Sans;
    font-size: 25.6px;
    font-weight: bold;
    line-height: 1.49;
    letter-spacing: 0.6px;
    text-align: center;
    color: #393b44;
    padding-bottom:30px;
    position:relative;
    &:after {
      content:'';
      width:35px;
      height:2px;
      background:#393b44;
      position:absolute;
      bottom:0;
      margin:0 auto;
      left:0;
      right:0;
    }
  }
}
#Founder {
  h2 {
    margin-top:45px;
  }
  img {
    width:100%;
  }
  p.quptes {
    font-family: Open Sans;
    font-size: 19px;
    font-style: italic;
    line-height: 1.69;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    span {
      font-size: 13.3px;
      line-height: 1.69;
      letter-spacing: 0.3px;
      text-align: center;
      color: #ffffff;
      padding-top: 24px;
      display: block;
      font-style: normal;
      padding-bottom: 10px;
    }
  }
}
.row.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.absolutediv {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
#Intellichip {
  display: block;
  img.product {
    width: 100%;
  }
  h3 {
    font-family: Open Sans;
    font-size: 47.7px;
    line-height: 1.68;
    text-align: justify;
    color: #393b44;
    margin-top: 10px;
  }
  p.points {
    font-family: Open Sans;
    font-size: 11.2px;
    line-height: 1.42;
    color: #393b44;
    margin-top: 30px;
  }
  .imagediv {
    text-align: center;
    .chip {
      margin-top: 100px;
    }
    .minitcardf {
      margin-top: 47px;
    }
    .minitcardb {
      margin-top: 10px;
      margin-bottom: 70px;
    }
    .celular {
      margin-top: 48px;
    }
  }
}
#Belnoir, #Mitobioprint, #codeEssential, #jewel, #jewelcard {
  img.belnoirtopimg {
    width: 100%;
  }
  .belnoirbottom {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 80px;
    img{
      &:nth-child(2) {
        margin-left: 25px;
      }
    }
  }
}
#jewel, #jewelcard {
  .jewelbottom {
    background: #b03427;
    padding: 63px 0;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 57px;
    .title {
      font-family: Open Sans;
      font-size: 30px;
      font-weight: 300;
      line-height: 55px;
      text-align: center;
      color: #ffffff;
    }
    a.button {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      text-transform: uppercase;
      border-radius: 3px;
      border:1px solid #fff;
      display: inline-block;
      padding: 0 10px;
      text-decoration: none;
      line-height: 55px;
    }
  }
}
.jewelregister {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.90);
  .form {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 500px;
    input[type="text"], input[type="email"] {
      width: 100%;
      border: solid 1px #7d7d7d;
      border-radius: 3px;
      object-fit: contain;
      font-family: Open Sans;
      font-size: 15px;
      line-height: 50px;
      text-align: left;
      color: #ffffff;
      padding-left: 21px;
      padding-right: 21px;
      outline: none;
    }
    input[type="email"] {
      margin: 29px 0 51px 0;
    }
    input[type="submit"] {
      width: 100%;
      border-radius: 3px;
      background-color: #b03427;
      line-height: 56px;
      text-align: center;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      outline: none;
      border: none;
    }
  }
}

.jewelcard {
  .contheading {
    color: #8f8f8f;
  }
  .tabs-menu li {
    a {
      color: #8f8f8f;
      pointer-events: none;
    }
    &.current a {
      color: #b03427;
    }
  }
}
#jewelcard {
  display: block;
  .jewelcardleft {
    width: calc(33.33333333% + 15px);
    margin-left: -15px;
    img {
      width: 100%;
    }
  }
  .jewelcardright {
    width: calc(50% + 15px);
  }
  .jewelcardright_top {
    width: calc(100% + 15px);
    margin-right: -15px;
    img {
      width: 100%;
    }
  }
  .jewelcardright_bottom {
    width: calc(100% + 15px);
    margin-right: -15px;
    margin-top: 72px;
    img {
      width: 100%;
    }
  }
  .jewelcardcenter {
    text-align: center;
    .jewelcardcenterr {
      position: absolute;
      max-width:150px;
      left: 0;
      right: 0;
      top: 50%;
      transform: translate(0, -50%); 
      margin: 0 auto;
      h2 {
        font-family: 'Montserrat';
        font-size: 36.9px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.9px;
        text-align: center;
        margin-bottom: 28px;
        color: rgba(255, 255, 255, 0.9);
      }
      p {
        font-family: Open Sans;
        font-size: 41.1px;
        font-weight: 300;
        line-height: 1.3;
        letter-spacing: 1px;
        text-align: center;
        color: #ffffff;
        position: relative;
        padding-bottom: 30px;
        &:after {
          content:'';
          position: absolute;
          width: 35px;
          height: 2px;
          background: rgba(255,255,255,0.9);
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: 0;
        }
      }
    }
  }
}
.jewellastbottom {
  text-align: center;
  margin-top: 51px;
  p {
    margin-top: 13px;
    margin-bottom: 56px;
    font-family: Open Sans;
    font-size: 13.1px;
    font-weight: 300;
    letter-spacing: 0.3px;
    text-align: center;
    color: #ffffff;
  }
}
.jewellastbottom + .col-md-12 a {
    width: 210px;
    line-height: 55px;
    border-radius: 3px;
    background-color: #393b44;
    border: solid 1px #393b44;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    display: block;
    margin: 0 auto;
    margin-bottom: 28px;
}
.servicemain {
  margin-top: 50px;
}
.securityp {
  font-family: 'Montserrat' !important;
  font-size: 21.4px !important;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
}
.customersupportm {
  margin-top: 45px;
  h4 {
    font-family: 'Montserrat';
    font-size: 21.5px;
    font-weight: bold;
    line-height: 1.11;
    letter-spacing: 0.4px;
    text-align: left;
    color: #393b44;
    margin-bottom: 17px;
  }
  p {
    font-family: Open Sans;
    font-size: 11.9px;
    line-height: 1.3;
    letter-spacing: 0.3px;
    text-align: left;
    color: #393b44;
  }
}
.missionvisionarea_customer {
  text-align: left !important;
  h2 {
    text-align: left !important;
  }
  p {
    text-align: left !important;
    &:after {
      display:none !important;
    }
  }
}
#section2, #section4, #section5, #section6, #section7, #section8, #section10{
  padding-top: 3%;
  padding-bottom: 3%;

  
  .section2-row1-col{
    font-family:'Open Sans';
    padding-bottom: 20px;
    span{
      font-size: 14px;
      font-style: italic;
      line-height: 1.71;
      text-align: left;
      color: #00abeb;
    }
    h2{
      font-size: 36px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.9px;
      text-align: center;
      color: #344cfb;
      font-style: initial;
    }
    .dark-grey{
      font-family:'Open Sans';
      font-style: initial;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      color: #71706e;
    }
  }
  .section2-row2-col{
    font-size: 18px;
    font-style: italic;
    line-height: 1.5;
    text-align: center;
    color: #71706e;
    font-family: 'Open Sans';
    p, h5{
      padding-bottom: 20px;
    }
  }
}

#section3{
  background-color: #f5f9fc;
  padding-top: 3%;
  padding-bottom: 3%;
  .section3-content{
    .row{
      position: relative;
      .section3-content-text{
        font-family: 'Open Sans';
        font-style: Italic;
        h2{
          font-size: 36px;
          font-weight: bold;
          line-height: 1;
          letter-spacing: 0.9px;
          text-align: left;
          color: #344cfb;
          padding-bottom: 32px;
          font-family: Montserrat;
          font-style: initial;
        }
        p{
          font-family: 'Open Sans';
          font-size: 18px;
          font-style: italic;
          line-height: 1.5;
          /*letter-spacing: 0.5px;*/
          text-align: left;
          color: #535252;
        }
      }
    }
    .row:first-child{
      z-index: 2;
      .section3-content-text{
        padding-left: 50px;
      }
    }
    .row:last-child{
      z-index: 1;
      top: -50px;
      .section3-content-text{
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: 340px;
      }
      .section3-content-img{
        float: right;
      }
    }
  }
}

#section4{
  background-color: #ffffff;
}

#section5, #section6, #section7{
  .list-block{
    &>div{
     padding-top: 10px;
     letter-spacing: -0.5px;
    }
  }
}

#section5{
  .section5-content{ 
    max-width: 782px;
    margin-left: auto;
    margin-right: auto;
  }
  .section5-row1{
      h2{
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-family: Montserrat;
      }
    }
  background-color: #f5f9fc;
  .list-block{
    &>div:before{
      content: url(../images/checked-icon.png);
      position: relative;
      left: -15px;
    }
  }
}

#section6{
  background-color: #00acec;
  .container{width:1250px;}
  .section2-row1-col{
    h2{
      font-size: 24px;
      font-weight: bold;
      line-height: 1.04;
      text-align: center;
      color: #ffffff;
      font-style: normal;
      font-family: Montserrat;
    }
  }

  .section2-row2-col{
    color: #ffffff;
  }
  .list-block{
    &>div:before{
      content: url(../images/checked-icon.png);
      position: relative;
      left: -15px;
    }
  }
}

#section7{
  background-color: #f5f9fc;
  .list-block{
    &>div:before{
      content: url(../images/checked-icon.png);
      position: relative;
      left: -15px;
    }
  }
  .section7-row1-col{
    h2{
      font-family: Montserrat;
      font-size: 24px;
      font-weight: bold;
      color: #272cfa;
    }

  }
}

#section9{
  .card-block{
    padding: 15px;
    .card-block-content{
      overflow: hidden;
      height: 335px;
      /*background-color: blue;*/
      position: relative;
      .card-block-content-center{
        text-align: center;
        padding: 20px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        h5{
          font-family: Montserrat;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.67;
          letter-spacing: -0.4px;
          text-align: center;
          color: #272cfa;
          padding-bottom: 10px;
        }
        ul{
          font-family: 'Open Sans';
          font-size: 14px;
          line-height: 1.71;
          text-align: center;
        }
      }
    }
  }

  .card-block:nth-child(odd){
    .card-block-content{
      background-color: #f5f9fc;
      color: #535252;;
    }
  }

  .card-block:nth-child(even){
    .card-block-content{
      background-color: #00abeb;
      color: #ffffff;
    }
  }
}


#section10{
  overflow: hidden;
  img{
    display: inline-block; padding-right: 9px;
  }
  .card-block{
    padding: 15px;
    height: 200px;
    /*background-color: blue;*/
    position: relative;
    h5{
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      line-height: 1.67;
      letter-spacing: -0.4px;
      text-align: left;
      color: #272cfa;
      padding-bottom: 10px;

    }
    p{
      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 1.71;
      text-align: left;
      color: #71706e;
    }
  }
  .row3{ 
    .row3-col{
      .innovation-today{
      font-family: Montserrat;
      font-size: 36px;
      font-weight: bold;
      line-height: 0.83;
      text-align: center;
      color: #272cfa;
      }
    }
  }
  .row4{
    .row4-col2{
      h5{
        font-family: Montserrat;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.09;
        text-align: left;
        color: #272cfa;
      }
      
    }
  }
}

#section11{
  padding-top: 3%;
  padding-bottom: 106px;
  &>.row{
    background-color: #00abeb;
  }
  .section11-img, .section11-right{
    width: 50%;
    position: relative;
  }
  .section11-right{
      font-family: 'Montserrat';
     .btn-wrapper{
      width: 100%;
      padding: 30px 70px;
      
    }     
    .section11-topContainer{
      background-color: #00abeb;
      padding-top: 15%;
      padding-bottom:15%;
      h5{
          font-size: 22.3px;
          font-weight: bold;
          line-height: 1.33;
          letter-spacing: 0.6px;
          text-align: center;
          color: #ffffff;
      }
      p{
          font-size: 17.4px;
          font-weight: 300;
          line-height: 1.71;
          letter-spacing: 0.4px;
          text-align: center;
          color: #e5f0f6;
      }
      .btn{
        background-color: #e5f0f6;
        border: none;
        border-radius: 0;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.71;
        letter-spacing: 0.7px;
        text-align: center;
        color: #00abeb;
        width: 266px;
        padding-top: 2%;
        padding-bottom: 2%;
      }
      
    }
    .section11-bottomContainer{
        background-color: #e5f0f6;
        box-shadow: 22px 38.1px 49px 0 rgba(0, 0, 0, 0.23);
        padding-top: 15%;
        padding-bottom:12%;
      h5{
          font-size: 22.3px;
          font-weight: bold;
          line-height: 1.33;
          letter-spacing: 0.6px;
          text-align: center;
          color: #272cfa;
      }
      p{
          font-size: 17.4px;
          font-weight: 300;
          line-height: 1.71;
          letter-spacing: 0.4px;
          text-align: center;
          color: #535252;
      }
      .btn{
        background-color: #00abeb;
        border: none;
        border-radius: 0;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.71;
        letter-spacing: 0.7px;
        text-align: center;
        color: #ffffff;
        width: 260px;
        padding-top: 2%;
        padding-bottom: 2%;
      }
    }
  }
}

.footer{
  position: relative;
  border-top: 2px #b03427 solid;
  background-color: #1b1d22;
  padding-top: 24px;
  padding-bottom: 31px;
  font-size: 10px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.3px;
  color: #ffffff;
  .container{
    width: 80vw;
    @media screen and (max-width: 1367px){
      width: 95%;
    }
  }
  .footer-nav-wrapper{
    text-align:center;
    a {
      float:none;
      font-family: Open Sans;
      font-size: 12px;
      line-height: 1.58;
      letter-spacing: 0.3px;
      text-align: center;
      color: #ffffff;
      padding-right:11px;
      position:relative;
      text-decoration:none;
      &:after {
        content:'|';
        right:0;
        position:absolute;
      }
      &:last-child:after {
        display:none;
      }
    }
    
  }
}
#section1-terms .row1 p, #section1-privacy .row1 p {
  font-family: Open Sans;
  font-size: 29.2px !important;
  line-height: 0.93 !important;
  letter-spacing: 1.5px !important;
  text-align: center !important;
  color: #393b44 !important;
  font-style: normal !important;
}
#section1-terms .row2 h2, #section1-privacy .row2 h2 {
  text-align: center !important;
  margin-bottom: 33px;
  font-family: Open Sans;
  font-size: 48.1px !important;
  font-weight: bold !important;
  line-height: 1.74 !important;
  color: #393b44 !important;
}
#section1-terms .row2 p, #section1-terms .row2 ol, #section1-privacy .row2 ol, #section1-privacy .row2 p {
  font-family: Open Sans;
  font-weight: normal !important;
  font-size: 11px !important;
}
.contact {
  margin-top: 54px;
  h2 {
    font-family: 'Montserrat';
    font-size: 21.4px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.5px;
    text-align: center;
    color: #393b44;
  }
  p {
    margin-top: 28px;
    font-family: Open Sans;
    font-size: 10.7px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    text-align: center;
    color: #393b44;
    padding-bottom: 25px;
    position: relative;
    &:after {
      content:'';
      position: absolute;
      bottom: 0;
      width: 36px;
      height: 2px;
      background: #393b44;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .field {
    margin-top: 78px;
    input[type="text"] {
      width: calc(100% - 25px);
      margin: 0 auto;
      line-height: 44px;
      border: solid 1px #393b44;
      font-family: Open Sans;
      font-size: 12px;
      font-style: italic;
      letter-spacing: 0.3px;
      text-align: left;
      color: #535252;
      padding: 0 10px;
      display: block;
      outline: none;
    }
    textarea {
      border: solid 1px #393b44;
      font-family: Open Sans;
      font-size: 12px;
      font-style: italic;
      letter-spacing: 0.3px;
      text-align: left;
      color: #535252;
      min-height: 168px;
      outline: none;
      resize: none;
      overflow-x: hidden;
      display: block;
      width: calc(100% - 25px);
      margin: 16px auto 36px auto;
      padding: 5px 10px;
    }
    input[type="submit"] {
      width: 267px;
      height: 50px;
      object-fit: contain;
      border-radius: 3px;
      background-color: #b03427;
      outline: none;
      border: none;
      text-transform: uppercase;
      line-height: 44px;
      display: block;
      margin: 36px auto 100px auto;
      font-family: Open Sans;
      font-size: 17.3px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
    }
  }
}

#appLogin{
  input{
    font-size: 10.5px;
    line-height: 1.36;
    text-align: left;
    color: #393b44;
    margin-left : 15px;
  }
  a{
    margin-left: 15px;
  }
  .btn-maroon{
    font-family: "Lato";
    font-size: 10.5px;
    font-weight: 900;
    line-height: 1.36;
    color: #ffffff;
    background-color: #b03427;
    margin-left: 15px;
  }
  .forgotpasslink{
    font-family: "Lato";
    font-size: 10.5px;
    line-height: 1.57;
    color: #a1b1bc;
    span{
      font-weight: bold;
      color: #b03427;
    }
  }
}