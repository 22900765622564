.nowrap{
  white-space: nowrap;
}

.strong{
  font-weight: bolder;
}

.dark-grey{
  color: #71706e !important;
}

.flex{
  display: flex;
}

.p0{
  margin: 0;
}

.m-b20{
	margin-bottom: 20px;
}

.letter-space-1{
    letter-spacing: -1px;
}

hr{
	border-top: 2px solid #00abeb !important;
	width: 57px;
	margin-bottom: 0;
}

.static-center{
    margin-left: auto;
    margin-right: auto;	
}



.icon-to-top{
    z-index: 100;
    cursor: pointer;
    border-radius: 50%;
    border: 23px #00abeb solid;
    position: absolute;
    right: 20%;
    top: -23px;
    width: 0;
    height: 0;
    span{
        position: absolute;
        transform: translate(-50%, -50%);
        padding: 10px;
        color: #ffffff;
    }
    span.glyphicon-chevron-up:before{
        padding: 10px;
    }
}

.icon-to-top:hover{
    border-color: #ffffff;
    span{
        color: #00abeb;
    }
}

.montse{
    font-family: Montserrat;
}
.opensans-normal{
    font-family: 'Open Sans'!important;
    font-style: normal!important;
}
.openSansItalic{
    font-family: 'Open Sans'!important;
    font-style: Italic!important;
}

