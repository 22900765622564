#section1-privacy{
  padding-top: 5%;
  padding-bottom: 3%;
  .row1{
    padding-top: 1%;
    padding-bottom: 2%;
    img{display: inline-block;}
    h2{
      font-family: Montserrat;
      font-size: 58.6px;
      font-weight: bold;
      line-height: 1.05;
      letter-spacing: 2.9px;
      text-align: center;
      color: #272cfa;
      
    }
    p{
       font-size: 29.2px;
      font-weight: 500;
      font-style: italic;
      line-height: 0.93;
      letter-spacing: 1.5px;
      text-align: center;
      color: #344cfb;
      span{
        font-weight: 300;
      }
    }
  }
  .row2{
    font-family: Lato;
    h2{
      font-size: 48.1px;
      font-weight: 900;
      line-height: 1.74;
      text-align: justify;
      color: #272cfa;
    }
    ol, p{
      font-size: 15px;
      font-weight: 900;
      text-align: left;
      color: #71706e;
      padding-left: 0;
      li{
        padding-bottom: 10px;
        list-style: decimal;
      }
    }



  }
}


#section1-terms{
  padding-top: 3%;
  padding-bottom: 3%;
  .row1{
    padding-top: 1%;
    padding-bottom: 2%;
    img{display: inline-block;}
    h2{
      font-family: Montserrat;
      font-size: 58.6px;
      font-weight: bold;
      line-height: 1.05;
      letter-spacing: 2.9px;
      text-align: center;
      color: #272cfa;
      
    }
    p{
       font-size: 29.2px;
      font-weight: 500;
      font-style: italic;
      line-height: 0.93;
      letter-spacing: 1.5px;
      text-align: center;
      color: #344cfb;
      span{
        font-weight: 300;
      }
    }

  }
  .row2{
    font-family: Lato;
    h2{
      font-size: 48.1px;
      font-weight: 900;
      line-height: 1.74;
      text-align: justify;
      color:  #272cfa;
    }
    ol, p{
      font-size: 13px;
      font-weight: bold;
      text-align: left;
      color: #71706e;
      padding-left: 0;
      li{
        padding-bottom: 10px;
        list-style: decimal;
      }
    }


  }
}