#registration, #forgotPassword, #resetPassword, #cardApplication{
    h2{
        font-family: "Montserrat";
        font-size: 21.4px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.5px;
        text-align: center;
        color: #393b44;
        margin-bottom: 15px;
    }
    .reg_subtitle{
        font-family: "OpenSans";
        font-size: 10.7px;
        line-height: 1.5;
        letter-spacing: 0.3px;
        text-align: center;
        color: #393b44;
    }
    .seperator{
        margin: 15px;
        background-color: #393b44;
        height: 2px;
        width: 36px;
        margin-left: auto;
        margin-right: auto;
    }
    input{
        // background-color: #393b44;
        border: solid 1px #393b44;
        margin-right: 15px;
        font-family: OpenSans;
        font-size: 12px;
        font-style: italic;
        line-height: 2;
        letter-spacing: 0.3px;
        text-align: left;
        color: #535252;
        border-radius: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    input:focus{
        color: #393b44;
        font-style: normal;
    }
    .email{
        border: 1px solid #a1b1bc;
        border-radius: 7px;
        padding-left: 5px;
    }
    .btn-maroon{
        background-color: #b03427;
        // box-shadow: 0px 2px 0 0 #1ea78c, inset 0px 1px 0 0 rgba(62, 255, 217, 0.75);
        font-family: "OpenSans";
        font-size: 17.3px;
        font-weight: 600;
        line-height: 1.36;
        color: #ffffff;
        padding-left: 30px;
        padding-right: 30px;
    }
}