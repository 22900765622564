
// Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,900,900i);

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Variables
@import "variables";
@import 'reset';
@import 'home';
@import 'assets';
@import 'privacy';
@import 'registration';
